// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

form {
  margin-top: $pad;

  p {
    display: none;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden] {
    display: none;
  }

  a:focus {
    outline: thin dotted;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  abbr[title] {
    border-bottom: 1px dotted;
  }

  b,
  strong {
    font-weight: bold;
  }

  dfn {
    font-style: italic;
  }

  hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
  }

  mark {
    background: #ff0;
    color: #000;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace,serif;
    font-size: 1em;
  }

  pre {
    white-space: pre-wrap;
  }

  q {
    quotes: "\201C" "\201D" "\2018" "\2019";
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    border: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  figure {
    margin: 0;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  legend {
    border-bottom:2px solid #edf2f7;
    padding: 0 1.5rem 1.5rem 1.5rem;
    width: 100%;
  }

  button,
  input,
  select,
  textarea {
    border: 1px $brand-10 solid;
    background-clip: padding-box;
    border-radius: 2px;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 1.2em 1.2em;
    word-break: normal;
    line-height: inherit;
    margin-top: 0.5rem;
    font-weight:400;
    font-size: 16px;
    margin-bottom: 0.8rem;

    &:focus{
      border-color: #000;
      outline: none;
    }
  }

  .sel{
    font-weight:700;
  }

  label{
  font-size: 0.9em;
    line-height: 1.3em;
  margin-bottom: 2rem;
  margin-top: 3rem;
  }

  button,
  input {
    line-height: normal;
  }

  button,
  select {
    text-transform: none;
  }

  .fui-row{
    margin-bottom: 10px;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
    width: max-content;
    padding: 25px 45px;
    background-color: #813127;
    color:#fff;
    &:hover{
      background-color: transparent;
      border: solid 1px #000;
      color:#000;
    }
  }

  .fui-error-message{
    background-color: #FAEAE8;
    border:solid 1px #DD5959;
    padding: 4px;
    margin: 10px 0;
    font-size: 11px;
    color: #DD5959;
    width: max-content;
    border-radius: 3px;

  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
}

.checkmark-contain {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-contain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}


.checkmark {
    padding: 10px;
}

/* On mouse-over, add a grey background color */
.checkmark-contain:hover input ~ .checkmark {
  background-color: $brand-pale;
}

/* When the checkbox is checked, add a blue background */
.checkmark-contain input:checked ~ .checkmark {
  background-color: $brand-pale;
  border-color: $brand-full;
}

.radio-style{
  padding-left: 35px;
margin-bottom: 12px;
cursor: pointer;
font-size: 22px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;

input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 9999px;
}

/* On mouse-over, add a grey background color */
&:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
 input:checked ~ .radio-mark {
  background-color: $brand-full;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radio-mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
 input:checked ~ .radio-mark:after {
  display: block;
}

/* Style the checkmark/indicator */
 .radio-mark:after {
   left: 12px;
       top: 8px;
       width: 5px;
       height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
}

.stripe-payment-intents-form{
  width: 100% !important;
.grid{
  padding: 1.5rem 1.5rem 0;
}

}

.paymentSource-form{

  button{
    margin: 1.5rem auto;
    width: 100%;
    max-width: 300px;
    width: -webkit-fill-available;
  }
}

.paypal-rest-form{
  padding: 0 1.5rem;
}

@media only screen and (min-width: 150px){
.paypal-button:not(.paypal-button-card) {
    height: 45px !important;
    min-height: 25px;
    max-height: 55px;
}
}

.card-data{
  margin: 5px 1.5rem;
  padding: 0.8rem 1rem;
  border-radius: 3px;
  border: 2px #F3F5FB solid;
  color: rgba(76, 76, 76, 0.5);
}
