// General stuff
.banner_section{
  height:80vh;
}

.caption-box:before{
  content: "";
  left:0;
  right:0;
  position: absolute;
  top: -45%;
  margin: 0 auto;
  width:1px;
  height:70%;
  background-color:#fff;
}

.caption-box:after{
  content: "";
  left:0;
  right:0;
  position: absolute;
  top: -45%;
  border-radius:10px;
  margin: 0 auto;
  width:5px;
  height:5px;
  background-color:#fff;
}
