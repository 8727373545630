@charset 'UTF-8';
@tailwind base;
@tailwind components;
@tailwind utilities;

main ul, section ul {
  list-style: disc outside none;
  padding-left: 0;
  margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 0px;
margin-inline-end: 0px;
padding-inline-start: 40px;
line-height: 1.5;
color: #868D9B;
}

main li, section li {
    display: list-item;
    text-align: -webkit-match-parent;
    padding-bottom: 4px;
}


// 1. Configuration and helpers
@import
  'utils/variables',
  'utils/functions',
  'utils/mixins';

// 2. Vendors
@import
  'vendor/normalize',
  'vendor/plugins';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/general';

// 4. Layout-related sections
@import
  'layout/layout',
  'layout/header',
  'layout/footer',
  'layout/content',
  'layout/nav';

// 5. Components
@import
  'components/button',
  'components/effects',
  'components/forms',
  'components/global',
  'components/images',
  'components/commerce/add-to-cart',
  'components/commerce/minicart';

// 6. Page-specific styles
